// metrics in "rem" not "px"
const pxToRem = (val) => `${val / 16}rem`;

/**
 * MUI Typography
 * @see https://mui.com/material-ui/customization/typography/
 */
const typography = {
  fontFamily: '"Noto Sans JP", sans-serif',
  semiBold: 600,
  // font weight bold
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 700,
  },
  h2: {
    fontSize: pxToRem(42),
    fontWeight: 700,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: 700,
  },
  h4: {
    fontSize: pxToRem(30),
    fontWeight: 700,
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 700,
  },
  h6: {
    fontSize: pxToRem(18),
    fontWeight: 700,
  },
  // font weight medium
  title: {
    fontSize: pxToRem(26),
    fontWeight: 500,
  },
  title2: {
    fontSize: pxToRem(20),
    fontWeight: 500,
  },
  title3: {
    fontSize: pxToRem(15),
    fontWeight: 500,
  },
  title4: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  title5: {
    fontSize: pxToRem(18),
    fontWeight: 600,
  },
  title6: {
    fontSize: pxToRem(13),
    fontWeight: 500,
  },
  title7: {
    fontSize: pxToRem(18),
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  subtitle3: {
    fontSize: pxToRem(12),
    fontWeight: 500,
  },
  // font weight regular
  body: {
    fontSize: pxToRem(18),
    fontWeight: 400,
  },
  body1: {
    fontSize: pxToRem(16),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body3: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  body4: {
    fontSize: pxToRem(10),
    fontWeight: 400,
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  // font weight light
  caption: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: 300,
  },
  desc: {
    fontSize: pxToRem(13),
    fontWeight: 400,
  },
  filter: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  update: {
    fontSize: pxToRem(10),
    fontWeight: 400,
  },
  header: {
    fontSize: pxToRem(40),
    fontWeight: 600,
  },
};

export default typography;
