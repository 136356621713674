import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from 'components/atoms/Loader';
import routes from './routes';

function Router() {
  const DashboardLayout = lazy(() => import('templates/Authenticated'));
  const GuestTemplate = lazy(() => import('templates/Guest'));

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map((route, i) => {
          const Page = lazy(() => import(`../${route.component}`));
          const layout = route.auth ? (
            <DashboardLayout
              navbar={route.navbar}
              sidebar={route.sidebar}
              footer={route.footer}
              allowedRoles={route.allowedRoles}
            />
          ) : (
            <GuestTemplate navbar={route.navbar} footer={route.footer} />
          );

          return (
            <Route key={i} element={layout}>
              <Route exact path={route.path} element={<Page />} />
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default Router;
