const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email_format: 'Please input correct email address format.',
      email: 'Please input correct email format.',
      password: {
        minLength: 'Password must be 8~20 in length.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
      positive_num: 'This field must be a positive number.',
      image: {
        invalid_type: 'Not a valid file format.',
        invalid_type_with_size:
          'Invalid image input. Image dimensions must be 1236x717 and file size should not be greater than 5MB.',
      },
      name: {
        max: 'The field must not be greater than 255 characters.',
      },
      date: {
        invalid_prev_date: 'The selected date and time must be current or onwards.',
      },
      katakanaOnly: 'Please enter furigana in katakana',
      email_max: 'The field must not be greater than 50 characters.',
      details_max: 'The field must not be greater than 50 characters.',
      template: {
        duplicate: 'A template with the same name has been registered.',
      },
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      login: 'Login',
      signup: 'Sign Up',
      signin: 'Sign In',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Password?',
      email_address: 'Email Address',
      email: 'Email',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
      welcome: 'Welcome,',
      menu: 'Menu',
      templates: 'Templates',
      contact: 'Contact',
      terms_privacy: 'Terms and Conditions • Privacy Policy',
      register_free: 'Register Free',
      logout_message: 'Would you like to logout?',
      create: 'Create New',
      edit: 'Edit',
      delete: 'Delete',
      start_editing: 'Start Editing',
      tool_tip: 'Please log in to add favorites.',
      group_template: 'Group Template',
      select_template: 'Select Template',
      cancel_modal: 'No, Cancel',
      confirm_modal: "Yes, I'm Sure",
      select: 'Please Select',
      upload_image: 'Upload image',
      no_file_chosen: 'No file chosen',
      no_data: 'No data.',
      all: 'All',
      menu_details: 'Menu Design Details',
      close: 'Close',
      public: 'Public',
      private: 'Private',
      insert_text: 'Insert Text',
      register: 'Register',
      renew: 'Renew',
      view: 'View',
      done: 'Done',
      about: 'About Us',
      terms_of_use: 'Terms of Use',
      try_web_version: `Try to use
      WEB version`,
      try_mobile_version: `Download from
      App Store`,
      about_web_version: 'About the Web version',
      about_mobile_version: 'About Download',
      back: 'Back',
      hours: 'hours',
      minutes: 'minutes',
      ago: 'ago',
      notifications: 'Notifications',
      print: 'Print Order',
      pdf: 'PDF Download',
    },
    pages: {
      login: {
        signup: {
          description: 'Do you have an account?',
        },
        invalid_auth: 'Incorrect email address or password.',
      },
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        sub_heading: 'To recover your account, please enter your email address below.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      templates: {
        template_list: 'Template List',
        name: 'Name',
        design: 'Design',
        menu: 'Menu',
        group: 'Group',
        disclosure: 'Disclosure',
        create: 'Create New',
        search: 'Search Keyword (ID, Name, Business Categories, Type)',
        designs: 'Designs',
        all: 'All',
        narrow_down: 'Narrow Down',
        refine_narrow_down: 'Refine/Narrow Down',
        sort: 'Sort By',
        new: 'New',
        old: 'Old',
        edit_register_template: 'Edit/Register Template',
        template_name: 'Template Name',
        group_tab: 'Group Tab',
        display_order: 'Display Order',
        template_image: 'Template Image',
        thumbnail_image: 'Thumbnail Image',
        edit_template: 'Edit Template',
        register_template: 'Register Template',
        jp: 'Japanese',
        en: 'English',
        name_already_used: 'Name already existed cannot be used.',
        multiple_select_possible: 'Multiple selection possible.',
        display_sort_order:
          'They will be sorted in this numerical order in the template list (if not specified, they will be at the end).',
        delete_template_confirmation: 'Are you sure you want to delete this template?',
        no_group_tabs: 'No available group tabs for the selected group.',
        delete_confirmation: 'Are you sure you want to delete this template?',
        template_deleted_message: 'Template has been deleted.',
        template_updated_message: 'Template details have been updated.',
        menu_design_deleted_message: 'Menu Design has been deleted.',
        open_editor: 'Open editor',
        business_categories: 'Business Categories',
        business_type: 'Business Type',
        type: 'Type',
        saving_details: 'Saving template details.',
      },
      groups: {
        renew: 'Renew',
        group_list: 'Group List',
        group_id: 'Group ID',
        group_name: 'Group Name',
        login_id: 'Login ID',
        login_password: 'Login Password',
        membership_limit: 'Membership Limit',
        no_registered_members: 'No. of Registered Members',
        template: 'Template',
        max_number_of_member: 'Max number of Members',
        number_of_registered_member: 'Number of Registered Members',
        tab: 'Tab',
        delete_confirmation: 'Are you sure you want to delete this group?',
        group_tab: 'Group Tab',
        create_new: 'Create New',
        group_edit: 'Group Edit',
        eye_catching_image: 'Eye-catching image 1236 x 717',
        eye_catching_url: 'URL for eye catching',
      },
      notifications: {
        title: 'Title',
        message: 'Message',
        notification_list: 'Notification List',
        notification_deleted_message: 'Notification has been deleted',
        notification_category: 'Notification Category',
        schedule_transmission: 'Schedule date of transmission',
        delete_confirmation: 'Are you sure you want to delete notification?',
        create_notification: 'Create Notification',
        edit_notifification: 'Edit Notification',
        registration: 'Register',
        schedule_reservation_date: 'Schedule reservation date',
        schedule_message:
          'After this date and time, this message will be reserved and sent to the reserved devices in sequence. Once reserved, it cannot be changed.',
        category: {
          normal: 'Normal',
          important: 'Important',
          news: 'News',
        },
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Account Information',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Restaurant menus are',
        sub_heading: 'Free and easy to make',
        why_heading: 'Why use Base Template?',
        info_message: `The WEB version is
        now available for
        both web and
        Android!`,
        operation: {
          header: 'EASY OPERATION',
          title: `Easy to operate!
          Create a menu in just
          3 minutes`,
          description:
            'You can create professionally designed restaurant menus by simply taking a photo with your PC or smartphone, selecting from more than 600 professionally designed templates, and entering text. *It supports a variety of business categories such as izakaya, cafes, bars, bars, Chinese food, and more.',
          btnlbl: '→ For first time customers',
        },
        troubleshooting: {
          header: 'TROUBLE SHOOTING',
          title: `Solve your problems
          with menu creation and
          attracting customers !`,
          description:
            'The app has a printing function, so you can decorate the front of your restaurant or the inside of your restaurant. Furthermore, since it is equipped with a SNS share function, it is also effective in attracting customers by spreading the word through SNS.',
          btnlbl: '→ What is menu express?',
        },
        template_list: '→ Template List',
        quality_title: {
          first_title: 'created by professionals',
          second_title: '600 high-quality designs',
        },
        quality_description: `New templates are added every month, so you're sure to find the template you're looking for.`,
        contribution: {
          first_title: 'Effective Usage Scenes ',
          second_title: 'Menu Express contributes to the prosperity of your restaurant!',
          third_title: 'Guide to effective utilization',
          description: `Menu Express is all about the positives for your restaurant.
          Here are some effective ways to use it.`,
        },
        btn_details: 'See details →',
      },
      about: {
        main_heading: 'For First-Time Customers',
        page_title: 'What is Menu Express?',
        page_definition:
          'This is a useful service that solves restaurant issues! Are there any issues or concerns about your restaurant?',
        page_description: `The menu is part of the staff.
          Use the menu to quickly resolve
          the daily worries and issues your restaurants faces .
          Menu Express contributes to the success of restaurants.`,
        banner_first_title: 'Original menu on your smartphone',
        banner_second_title: 'Easy to create in seconds',
        banner_descriptions: `Once you have decided where and for what purpose you will use the
          menu, all you need to do is 4 easy steps.
          It is fully equipped with functions such as output service, SNS posting,
          and image correction.
          Find your favorite from a wide variety of menu templates.
          Because the food is made with care,
          Why not be particular about your menu?`,
        footer_description: `First, use the web version to
        make your store more attractive.`,
        footer_btn_first_label: 'Try to use',
        footer_btn_second_label: 'WEB version',
        steps: {
          take_photo: 'Take a photo ',
          process_photo: `Process the photos 
          you took`,
          enter_characters: `Enter characters 
          and amounts`,
          complete: `Complete!
          Print or disseminate
          information by posting 
          on SNS!`,
        },
        effects: {
          title: 'Examples of introduction effects',
          increase_price: {
            title: 'Increase in average spend per customer',
            description:
              'By using the "one more item" approach, such as a finishing dish or dessert after a meal, the average spend per customer increased compared to before creating the POP.',
          },
          cost_down: {
            title: 'Cost rate down',
            description:
              'By effectively utilizing excess inventory to prevent food waste and using a POP approach to menus with high profit margins, we were able to lower the cost rate and increase profits even with the same sales as before.',
          },
          increase_repeat: {
            title: 'Increase repeat rate',
            description:
              'By promoting pre-ordered banquet menus using rare ingredients to all tables through POP, we have increased the number of people making reservations for their next visit when they visit the restaurant, which was not the case before.',
          },
          increase_sales: {
            title: 'Increase in sales and branding',
            description:
              'By increasing the number of contact points in front of and inside the restaurant, the recommended menu items have become the "signature menu item" that most customers order.',
          },
        },
        how_to_use: {
          title: 'Examples of how to use it',
          food_loss: {
            title: 'Food loss cut',
            description:
              'When there is excess stock of ingredients due to variations in the number of items served, etc., you can prevent ingredient loss by creating and promoting new menus and assorted POPs that are not on the regular menu.',
          },
          opportunity_loss: {
            title:
              'Preventing opportunity loss by attracting customers stably even during slow periods',
            description:
              'Even when the number of customers usually decreases, such as on rainy days, you can stably attract customers by distributing POP created using templates such as "Rainy Day Campaign" on SNS in real time.',
          },
          improving_operations: {
            title: 'Improving "last mile" operations',
            description:
              'Rather than viewing the food used when creating POP as waste, hall staff can eat it and deepen their understanding of the taste and characteristics of the food, allowing them to properly guide customers about the food and improving operations.',
          },
        },
      },
    },
    menu: {
      home: 'Home',
      about: 'About Service',
      template_list: 'Template List',
      user_guide: 'User Guide',
      contact_us: 'Contact Us',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      my_page: 'MyPage',
      history: 'History',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
      templates: 'Template Management',
      designs: 'Menu Design Management',
      groups: 'Group Management',
      last_update: 'Last update',
      days: 'day(s) ago',
      months: 'month(s) ago',
      years: 'year(s) ago',
      today: 'today',
      guide: 'List of Effective Application Scenes',
      counter: 'Effective Storefront Presentation',
      store: 'Effective In-store Staging',
      sns: 'Effective use of SNS',
      templates_back: 'Back to Template List',
      account_information: 'View/Edit Account Information',
      change_password: 'Change Password',
      cancel_membership: 'Cancel Membership',
      notifications: 'Notification Management',
    },
    table: {
      no_data: 'No records found.',
    },
    select: {
      options: {
        general: 'General',
        all: 'All',
      },
    },
    template: {
      filters: 'Filters',
      search_template: 'Search templates',
      design_found: 'designs were found',
      design_empty: 'There are no applicable templates.',
      business_categories: {
        narrow_down: 'Narrow down business categories',
        all: 'All',
        izakaya: 'Izakaya',
        japanese_food: 'Japanese food, sushi, seafood dishes',
        udon_soba: 'Udon Soba',
        eel: 'Eel',
        yakitori: 'Yakitori',
        yakinikum_offal: 'Yakiniku, offal',
        tonkatsu: 'Tonkatsu and shabu-sabu',
        kushiage: 'Kushiage/Tempura',
        okonomiyaki: 'Okonomiyaki and Monjayaki',
        okinawan: 'Okinawan cuisine',
        western_food: 'Western food/French/Italian',
        steak_hamburger: 'Steak Hamburger',
        chinese_food: 'Chinese food/Korean food',
        curry_thai_food: 'Curry/Thai food',
        cafe: 'Cafe/Bar',
        others: 'Others',
      },
      refine: {
        narrow_type: 'Narrow down Type',
        all: 'All',
        vertical_type: 'Vertical Type',
        horizontal_type: 'Horizontal Type',
        daily_recommended: 'Daily/Recommended',
        limited_menu: 'Limited menu',
        seasonal_menu: 'Seasonal Menu',
        morning: 'Morning',
        lunch: 'Lunch',
        dinner: 'Dinner',
        luxury: 'Luxury',
        the_masses: 'The Masses',
        all_you: 'All-you-can-drink/All-you-can-eat',
        year_end: 'Year-end party/welcome and farewell party',
        banquet_course: 'Banquet/course meal',
        drink: 'Drink',
        take_out: 'Take-out',
        others: 'Others',
      },
      menu_management: {
        last_10_days: 'Last 10 days',
        newest_first: 'Newest First',
        oldest_first: 'Oldest First',
        favorites: 'Favorites',
        editing_and_edited: 'Editing and Edited',
        last_update_30: 'Last update 30min ago',
        last_update_8: 'Last update 8 hours ago',
      },
    },
    guide: {
      heading: 'Information and services aimed at effective store presentation',
      sub_heading: 'Full range of services and functions!',
      counter_content: `The presentation of the storefront and the appeal of the food is one of the most important factors in a customer's choice of restaurant.`,
      store_content:
        'Inside the store you can address your issues and concerns using tabletop and wallpaper.',
      sns_content:
        'Menus created with Menu Express can be distributed in real time via SNS, making it an effective tool for attracting customers and acquiring reservations for banquets and other events.',
    },
    counter: {
      point_1: 'Is the menu designed to give an idea of each dish?',
      point_content_1:
        'A menu with only text cannot convey the image of the food. By using a menu with photos, the image of the food can be conveyed even more clearly. Photos of food are especially important for storefront menus, as it is not possible to ask the staff about the menu.',
      point_2: 'You want people to know about your restaurant',
      point_content_2:
        'In addition to food templates, you can also provide information about your restaurant, such as holidays, hours of operation, non-smoking and smoking restrictions, etc., at the storefront to convey information about your restaurant and to motivate customers to visit.',
    },
    store: {
      point_1: 'The wall there, is there any reason not to use it?',
      point_content_1: `Do you know the place that is looked at the most, albeit casually? It is the wall surface around the tables. How about displaying POP of banquet or girls' party to attract reservations for the next time, or promoting a closing dish to increase the customer spend?`,
      point_2: 'It is also important to keep customers from getting bored.',
      point_content_2: `The menu changes daily with dishes using seasonal ingredients. Such a recommended menu is attractive, isn't it? However, changing the menu every day requires a lot of time and effort, but if you use a menu board that is easy to replace, there is no stress on site even if you change the menu every day. It is effective for promoting killer contents separately from the grand menu.`,
      point_3: 'We will bring you together.',
      point_content_3: `It will connect you with the right people. If you use a template for recruiting staff and have your store's fans and their friends and acquaintances apply for the position, you can reduce recruiting costs. The key is to make a subtle appeal rather than a big one.`,
    },
    sns: {
      point_1: `Distribute "Today's Daily Lunch" on SNS`,
      point_content_1: `Using Facebook and LINE@ to approach lunch menus during work hours will motivate customers to visit the restaurant.`,
      point_2: 'Rainy days and other opportunities to attract customers',
      point_content_2: `On rainy days, when customers are inevitably turned away, you offer "rainy day campaigns" and other services that are only available on rainy days in real time. This will help to differentiate your restaurant from others and attract customers.`,
      point_3:
        'Supporting increase in reservations by announcing recommended information in advance',
      point_content_3: `You can distribute information on limited-edition foods and seasonal fairs, such as "limited menu items that are sure to sell out," on SNS. This enables you to obtain reservations ahead of time, and thus can expect to attract a steady stream of customers.`,
    },
    contact: {
      header: `Thank you for your interest in Menu Express. Please feel free to contact us with any comments or requests.
      Please fill in the form below and click the "Confirm your entry" button when you are finished.`,
      item_entry: 'Item Entry',
      confirmation: 'Confirmation of input contents',
      transmission: 'Transmission complete',
      inquire_type: 'Inquiry Type',
      your_name: 'Your Name',
      furigana: 'Furigana',
      email: 'Email Address',
      inquire_details: 'Inquiry Details',
      required: 'Required',
      email_format: '(must be able to receive from ****@menuexpress.jp)',
      policy: 'Please read our <0> Privacy Policy </0> before proceeding to the confirmation page.',
      confirm_entry: 'Confirm your entry',
      submitted: `Your inquiry has been submitted.
      Please wait for our response.`,
      return: 'Return to the top page',
      back: 'Go back',
      send: 'Send',
      options: 'Opinions and Requests',
      about_defects: 'About Defects',
      others: 'Others',
      placeholder_name: 'Example: Hanako Yamada',
      placeholder_furigana: 'Example: ヤマダ ハナコ',
      placeholder_email: 'Example: example@example.com',
    },
  },
};

export default en;
