import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import Router from './router';
import theme from './theme';

function App() {
  return (
    <HelmetProvider>
      <CookiesProvider defaultSetOptions={{ path: '/', domain: '.menuexpress.jp' }}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}

export default App;
