import { roles } from 'config/userRole';

const routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/guest/NotFound',
    auth: false,
    navbar: 'top',
    footer: 'unauth',
  },
  {
    path: '/',
    component: 'pages/guest/Landing',
    auth: false,
    navbar: '',
    footer: '',
  },
  {
    path: '/users',
    component: 'pages/guest/Landing',
    auth: true,
    navbar: '',
    footer: '',
    sidebar: false,
    allowedRoles: [roles.admin, roles.user],
  },
  {
    path: '/dashboard',
    component: 'pages/authenticated/Dashboard',
    navbar: 'dashboard',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin, roles.user],
  },
  {
    path: '/admin/templates',
    component: 'pages/authenticated/admin/template',
    navbar: 'dashboard',
    auth: true,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/templates/create',
    component: 'pages/authenticated/admin/template/AddEditTemplate',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/templates/edit/:id',
    component: 'pages/authenticated/admin/template/AddEditTemplate',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups',
    component: 'pages/authenticated/admin/group',
    navbar: 'dashboard',
    auth: true,
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups/create',
    component: 'pages/authenticated/admin/group/AddEditGroup',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/groups/edit/:id',
    component: 'pages/authenticated/admin/group/AddEditGroup',
    navbar: 'dashboard',
    auth: true,
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification',
    auth: true,
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications/create',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    allowedRoles: [roles.admin],
  },
  {
    path: '/admin/notifications/edit/:id',
    navbar: 'dashboard',
    component: 'pages/authenticated/admin/notification/AddEditNotification',
    auth: true,
    sidebar: false,
    allowedRoles: [roles.admin],
  },
  {
    path: '/about',
    component: 'pages/guest/About',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/signup',
    component: 'pages/guest/Signup',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/login',
    component: 'pages/guest/Login',
    auth: false,
    navbar: 'unauth',
  },
  {
    path: '/forgot-password',
    component: 'pages/guest/ForgotPassword',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/password/reset',
    component: 'pages/guest/ResetPassword',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/activate',
    component: 'pages/guest/Activate',
    auth: false,
    navbar: 'unauth',
    footer: 'unauth',
  },
  {
    path: '/users/my-page',
    component: 'pages/authenticated/MyPage',
    auth: true,
    navbar: 'dashboard',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.admin, roles.user],
  },
  {
    path: '/terms',
    component: 'pages/guest/Terms',
    auth: false,
    navbar: 'top',
    footer: 'unauth',
  },
  {
    path: '/guide',
    component: 'pages/guest/UserGuide',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/counter',
    component: 'pages/guest/Counter',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/store',
    component: 'pages/guest/Store',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/sns',
    component: 'pages/guest/Sns',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/users/sns',
    component: 'pages/guest/Sns',
    auth: true,
    navbar: 'top',
    footer: '',
    sidebar: false,
    allowedRoles: [roles.admin, roles.user],
  },
  {
    path: '/contact',
    component: 'pages/guest/ContactUs',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/contact/confirmation/',
    component: 'pages/guest/contactpage/Confirmation',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/contact/complete',
    component: 'pages/guest/contactpage/Complete',
    auth: false,
    navbar: 'top',
    footer: '',
  },
  {
    path: '/privacy-policy',
    component: 'pages/guest/PrivacyPolicy',
    auth: false,
    navbar: 'top',
    sidebar: false,
    footer: 'unauth',
  },
  {
    path: '/templates',
    component: 'pages/guest/Templates',
    auth: false,
    navbar: 'unauth_templates',
    footer: '',
  },
  {
    path: '/users/templates',
    component: 'pages/authenticated/user/Templates',
    auth: true,
    navbar: 'user_auth',
    sidebar: false,
    footer: '',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/templates/create/:id',
    component: 'pages/authenticated/user/TemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/design/details/:id',
    component: 'pages/authenticated/user/TemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/designs/edit/:id',
    component: 'pages/authenticated/user/EditMenuDesign',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/designs/view/:id',
    component: 'pages/authenticated/user/TemplateDetails',
    auth: true,
    navbar: 'dashboard',
    sidebar: false,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
  {
    path: '/users/designs',
    component: 'pages/authenticated/user/MenuDesignManagement',
    auth: true,
    navbar: 'dashboard',
    sidebar: true,
    footer: 'unauth',
    allowedRoles: [roles.user],
  },
];

// Don't include styleguide in production routes
if (process.env.ENVIRONMENT !== 'production') {
  routes.push({
    path: '/styleguide',
    component: 'pages/guest/Styleguide',
    auth: false,
  });
}

export default routes;
